import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../redux/user/userSelections'
import Header from '../components/global-components/structure/header/Header'
import '../styles/editor-flow/find-replace/find-replace.scss'
import TableSortIcon from '../components/global-components/table/TableSortIcon'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import globalSearchFilter from '../utilities/table/globalSearchFilter'
import useAuthToken from '../hooks/useAuthToken'
import { ReactComponent as UnlockIcon } from '../assets/icons/unlock-icon.svg'
import {
  fetchLockedContent,
  selectSocketBlockedResources,
  unlockContentLock,
} from '../redux/socket/socket'
import MaterialLoader from '../components/global-components/elements/MaterialLoader'
import '../styles/editor-flow/manage-content-locks/manage-content-locks.scss'
import ManageContentLocksConfirmUnlockModal from '../components/editor-flow/manage-content-locks/ManageContentLocksConfirmUnlockModal'
import dayjs from 'dayjs'
import dateFormats from '../configuration/supportedDateFormats'
import ManageContentLocksDumpModal from '../components/editor-flow/manage-content-locks/ManageContentLocksDumpModal'

/*
  Disable prop-types as it triggers on every table Cell definition
 */
/* eslint react/prop-types: 0 */
function ManageContentLocksContainer() {
  const { token } = useAuthToken({})
  const clientId = useSelector(selectActiveClientId)
  const blockedResources = useSelector(selectSocketBlockedResources)
  const activeClient = useSelector(selectActiveClient)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const [isLoading, setLoading] = useState(true)
  const [lockToRemove, setLockToRemove] = useState(null)
  const [items, setItems] = useState([])
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)

  useEffect(() => {
    if (token) {
      fetchLocks()
    }
  }, [token, blockedResources])

  const data = useMemo(() => {
    return items
  }, [items])

  const columns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'user.firstName',
        width: 'auto',
        Cell: props => (
          <div>
            {props.cell.value} {props.row.original.user.lastName}
          </div>
        ),
      },
      {
        name: 'type',
        Header: 'Type',
        width: 'auto',
        accessor: row => getType(row.type),
      },
      {
        id: 'name',
        Header: 'Name',
        width: 'auto',
        accessor: row => {
          if (
            row?.type === 'configureWorkflow' ||
            row?.type === 'configureSteps'
          ) {
            return row.data.name
          } else if (
            row?.type === 'configureStep' ||
            row?.type === 'editStep'
          ) {
            return `${row.data.workflowName} > ${row.data.name}`
          }
          return ''
        },
      },
      {
        id: 'createdTime',
        Header: 'Created On',
        width: 'auto',
        accessor: row =>
          dayjs(row.createdTime).format(dateFormats.dayMonthYearTime),
      },
      {
        id: 'action',
        Header: 'Actions',
        disableSortBy: true,
        width: 200,
        Cell: props => (
          <button
            className="btn btn--light-green btn--no-border btn--icon-text-left"
            onClick={() => setLockToRemove(props.row.original)}
            data-tooltip="Remove Content Lock"
          >
            <UnlockIcon />
            <span>Unlock</span>
          </button>
        ),
      },
    ],
    [items],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalSearchFilter,
    },
    useGlobalFilter,
    useSortBy,
  )

  function getType(type) {
    switch (type) {
      case 'configureWorkflow':
        return 'Configure Workflow'
      case 'configureSteps':
        return 'Edit Workflow'
      case 'configureStep':
        return 'Configure Step'
      case 'editStep':
        return 'Edit Step'
    }
  }

  function fetchLocks() {
    setLoading(true)
    fetchLockedContent({ token, clientId })
      .then(res => {
        setItems(res?.data?.contentLocks)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  async function handleSubmitUnlockModal(lock) {
    setLockToRemove(null)
    setLoading(true)
    await unlockContentLock({ token, clientId, lock })
    await fetchLocks()
  }

  return (
    <div className="">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Manage Content Locks"
      />
      <div className="manage-content-locks section">
        <div className="container">
          <div className="table__filters">
            <div className="table__filters-row">
              <div className="table__filters-content">
                <p className="title">Locked Content</p>

                <div className="table__filters-counts">
                  <p>
                    <span>{items?.length}</span> Workflow
                    {items?.length > 1 ? 's' : ''}
                  </p>
                </div>
              </div>

              <div className="table__filters-actions">
                <button
                  className="btn btn--light-grey"
                  onClick={() => setIsStatusModalOpen(true)}
                >
                  View Status Log
                </button>
                <input
                  type="text"
                  className="filters-search"
                  name="search-users"
                  onChange={e => setGlobalFilter(e.currentTarget.value)}
                  placeholder="Search Results"
                />
              </div>
            </div>
          </div>
          <div className="table relative">
            <div className="table__container">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`search-results-thead-tr-${i}`}
                    >
                      {headerGroup?.headers?.map((column, i) => (
                        <th
                          key={`find-replace-thead-th-${i}`}
                          {...column.getHeaderProps()}
                          style={{
                            verticalAlign: 'middle',
                            width: column.width,
                          }}
                        >
                          <div {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                            <TableSortIcon column={column} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows?.map((row, i) => {
                    prepareRow(row)

                    return (
                      <tr
                        key={`find-replace-tbody-tr-${i}`}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              className={cell.column.id}
                              key={`find-replace-tbody-td-${i}`}
                              {...cell.getCellProps()}
                              style={{
                                width: cell.column.width,
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {isLoading ? (
                <MaterialLoader containerClasses="overlay-loader" />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {!!lockToRemove ? (
        <ManageContentLocksConfirmUnlockModal
          isOpen={!!lockToRemove}
          lock={lockToRemove}
          onRequestClose={() => setLockToRemove(null)}
          onSubmit={handleSubmitUnlockModal}
        />
      ) : null}
      {isStatusModalOpen ? (
        <ManageContentLocksDumpModal
          isOpen={isStatusModalOpen}
          onRequestClose={() => setIsStatusModalOpen(false)}
        />
      ) : null}
    </div>
  )
}

export default ManageContentLocksContainer
