import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import { ReactComponent as MessageIcon } from '../../../../assets/icons/message-alert-square-icon.svg'
import PropTypes from 'prop-types'
const ManageWorkflowStepBlockedModal = ({ resource, onStepsClick }) => {
  return (
    <Modal modalClassName="modal--delete-workflow modal--sm" isOpen={true}>
      <div className="modal__header">
        <h3 className="flex align-center">
          <MessageIcon />
          This step is already being edited
        </h3>
      </div>
      <div className="modal__body">
        <br />
        <p>
          <b>
            Oops! It seems {`${resource?.firstName} ${resource?.lastName}`} is
            already editing this section.
          </b>
        </p>
        <p>
          To avoid any accidental overlaps, this section is currently locked.
          You can choose to wait, or feel free to reach out to{' '}
          {resource?.firstName} and see when they might be done.
        </p>
        <p>
          Collaboration is great, but let&apos;s avoid stepping on each
          other&apos;s toes.
        </p>
      </div>
      <div className="modal__submit">
        <button className="btn btn--dark-purple" onClick={onStepsClick}>
          Go to Steps
        </button>
      </div>
    </Modal>
  )
}

ManageWorkflowStepBlockedModal.propTypes = {
  resource: PropTypes.object,
  onStepsClick: PropTypes.func,
}

export default ManageWorkflowStepBlockedModal
